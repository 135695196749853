<template>
    <div style="max-width: 450px;margin: 20px auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="140px" class="login-form">
            <el-form-item v-if="popTitle=='审批'" prop="stat" label="审核结果">
                <div>
                    <el-radio-group v-model="ruleForm.stat">
                        <el-radio :label="2">通过</el-radio>
                        <el-radio :label="3">不通过</el-radio>
                    </el-radio-group>
                </div>
            </el-form-item>
            <el-form-item v-if="ruleForm.stat==2" prop="n_store_limit" label="可开通代理账号数">
                <div class="flex">
                    <el-input class="flex-1" v-model="ruleForm.n_store_limit" placeholder="请输入可开通代理账号数"></el-input>
                    <span style="margin-left:15px">个</span>
                </div>
            </el-form-item>
            <el-form-item v-if="ruleForm.stat==2" prop="n_product_limit" label="可上传商品数">
                <el-select v-model="ruleForm.n_product_limit" style="width:280px" placeholder="请选择">
                    <el-option
                    v-for="item in nplOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="ruleForm.stat==2" prop="paid_amount" label="支付金额">
                <div class="flex">
                    <el-input class="flex-1" v-model="ruleForm.paid_amount" placeholder="请输入支付金额"></el-input>
                    <span style="margin-left:15px">元</span>
                </div>
            </el-form-item>
            <el-form-item v-if="ruleForm.stat==3" style="width:100%;position:relative;" prop="stat_msg" label="原因">
                <el-input type="textarea" v-model="ruleForm.stat_msg" :rows="3" :maxlength="150" placeholder="请输入原因"></el-input>
                <div style="position:absolute;right:14px;bottom:0">
                    <span style="line-height: 25px;color:#B3B3B3;" class="font14-grey">{{ruleForm.stat_msg && ruleForm.stat_msg.length || 0}}/150</span>
                </div>
            </el-form-item>
            <div class="flex flex-justify-around" style="padding-top:30px;">
                <div><el-button @click="hide()" style="width: 80px;" type="primary" plain>取消</el-button></div>
                <div v-if="popTitle=='查看审批'">
                    <el-button @click="hide()" style="width: 80px;" type="primary">确定</el-button>
                </div>
                <div v-else>
                    <el-button @click="submitForm()" style="width: 80px;" type="primary">提交</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { statGeneralAgent } from '@/service/sharer';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                rules: {
                    stat: [{ required: true, message: '请选择审核结果', trigger: 'blur' }],
                    n_store_limit: [{ required: true, message: '请输入可开通代理账号数', trigger: 'blur' }],
                    n_product_limit: [{ required: true, message: '请选择可上传商品数', trigger: 'blur' }],
                    paid_amount: [{ required: true, message: '请输入支付金额', trigger: 'blur' }],
                    stat_msg: [{ required: true, message: '请输入原因', trigger: 'blur' }],
                },
                nplOptions: [
                    { id: 20, name: '20' },
                    { id: 100, name: '100' },
                    { id: 200, name: '200' },
                    { id: 99999, name: '不限' }
                ],
                ruleForm:{
                    id:null,
                    stat: null,
                    stat_msg: null,
                    n_store_limit: null,
                    paid_amount: null
                },
            }
        },
        watch:{
            popTitle:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            },
            form:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            console.log(this.ruleForm);
        },
        mounted() {},
        methods: {
            hide() {
                this.$emit("hide");
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                statGeneralAgent(this.ruleForm).then(rst => {
                    this.$emit("refreshItems");
                    this.$emit("hide");
                    this.$message.success("提交成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
    
</style>