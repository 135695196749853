<template>
    <div class="zt-page-content">
        <div class="page-header" style="min-width:1600px;">分销申请</div>
        <div class="zt-block tab flex">
            <div class="tab_menu flex-1">
                <div v-for="(item,idx) in tabList" :key="idx" @click="tab(idx,item.id)" class="flex menu_item" :class="{'cur':tabIndex == idx}">
                    <span>{{item.name}}</span>
                </div>
            </div>
        </div>
        <div class="zt-block" style="height: calc(100% - 175px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="apply_at" label="申请日期">
                        <template slot-scope="scope">
                            <div>{{scope.row.apply_at | moment("YYYY/MM/DD")}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="comp_name" label="申请企业">
                        <template slot-scope="scope">
                            <div>{{scope.row.comp_name}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="n_store_limit" label="可申请账号数">
                        <template slot-scope="scope">
                            <div>{{scope.row.n_store_limit}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="n_product_limit" label="可上传商品数">
                        <template slot-scope="scope">
                            <div v-if="scope.row.n_product_limit==99999">不限</div>
                            <div v-else>{{scope.row.n_product_limit}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="paid_amount" label="支付金额">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.paid_amount}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.stat==1">已申请</div>
                            <div v-if="scope.row.stat==2">已通过</div>
                            <div v-if="scope.row.stat==3">已拒绝</div>
                            <div v-if="scope.row.stat==4">已启用</div>
                            <div v-if="scope.row.stat==5">已禁用</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="meter_number" label="操作">
                        <template slot-scope="scope">
                            <el-button @click="viewDetail(scope.row)" type="text">申请详情</el-button>
                            <el-button v-if="scope.row.stat!=1" @click="check(scope.row,2)" type="text">修改</el-button>
                            <el-button v-else @click="check(scope.row,1)" type="text">审批</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page" :page-size="queryOpt.pagesize" :total="total"></el-pagination>
            </div>
        </div>
        <el-dialog width="600px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <ShareDetail v-if="popTitle=='查看详情'" :form="ruleForm" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></ShareDetail>
            <ShareCheck v-else :form="ruleForm" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></ShareCheck>
        </el-dialog>
    </div>
</template>

<script>
    import { findItem } from '@/util';
    import { listGeneralAgent } from '@/service/sharer';
    import ShareCheck from './ShareCheck'
    import ShareDetail from './ShareDetail'
    export default {
        components: {
            ShareCheck, ShareDetail,
        },
        data() {
            return {
                popVisible: false,
                popTitle: null,
                tabList:[
                    {id:1,name:"工厂端"},
                    {id:3,name:"销售公司端"},
                ],
                tabIndex:0,
                queryOpt:{
                    belong_to_comp_type:[1],
                    page:1,
                    pagesize:10
                },
                total:0,
                nplOptions: [
                    { id: 20, name: '20' },
                    { id: 100, name: '100' },
                    { id: 200, name: '200' },
                    { id: 99999, name: '不限' }
                ],
                list:[],
                ruleForm:"",
                itemId:null,
            }
        },
        async created() {
            this.refreshItems();
        },
        methods: {
            goto(link) {
                this.$router.push(link);
            },
            // 选项卡
            tab(index,id) {
                this.tabIndex = index;
                this.queryOpt.belong_to_comp_type = [id];
                this.refreshItems();
            },
            refreshItems() {
                console.log('refreshItems');
                listGeneralAgent(this.queryOpt).then(rst => {
                    this.total = rst.total;
                    this.list = rst.list;
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            check(item,type) {
                if (type==1) {
                    this.popTitle = "审批";
                } else {
                   this.popTitle = "修改";
                }
                this.popVisible = true;
                this.ruleForm = {
                    id: item.id,
                    stat: item.stat,
                    stat_msg: item.stat_msg,
                    n_store_limit: item.n_store_limit,
                    n_product_limit: item.n_product_limit,
                    paid_amount: item.paid_amount
                };
            },
            viewDetail(item) {
                this.popTitle = "查看详情";
                this.popVisible = true;
                this.ruleForm = {
                    name:item.name,
                    contact_name:item.contact_name,
                    contact_mobile:item.contact_mobile,
                };
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>

    .tab {
        padding:0 20px;
    }
    .tab_menu .menu_item {
        display: inline-block;
        vertical-align: top;
        width: 80px;
        height: 55px;
        text-align: center;
        line-height: 55px;
        margin-right:50px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid #4D4D4D;
    }

    div>>> .el-table .cell {
        text-align: center;
    }
    div>>> .el-dialog {
        min-width: 550px;
    }
    div>>> .el-dialog__body {
        background: #F5F5F5;
    }

</style>