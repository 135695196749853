<template>
    <div style="max-width: 400px;margin: 20px auto;">
        <el-form v-if=ruleForm :model="ruleForm" ref="formCheck" label-width="110px" class="login-form">
            <el-form-item style="width:100%;position:relative;" label="店铺名称">
                <div>{{ruleForm.name}}</div>
            </el-form-item>
            <el-form-item style="width:100%;position:relative;" label="申请人">
                <div>{{ruleForm.contact_name}}</div>
            </el-form-item>
            <el-form-item style="width:100%;position:relative;" label="申请人手机号">
                <div>{{ruleForm.contact_mobile}}</div>
            </el-form-item>
            <div class="flex flex-justify-around" style="padding-top:30px;">
                <div>
                    <el-button @click="hide()" style="width: 80px;" type="primary" plain>取消</el-button>
                </div>
                <div>
                    <el-button @click="hide()" style="width: 80px;" type="primary">确定</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { applyAudit } from '@/service/bureau';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                ruleForm:null,
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            console.log(this.ruleForm);
        },
        mounted() {},
        methods: {
            hide() {
                this.$emit("hide");
            },
        }
    }
</script>
<style scoped>
    
</style>