import { postToShareServer } from './common';
/**
 * 列表总代理
 */
export async function listGeneralAgent(obj){
    return await postToShareServer('/list-general-agent',obj);
}
/**
 * 审核总代和修改状态
 */
export async function statGeneralAgent(obj){
    return await postToShareServer('/stat-general-agent',obj);
}
/**
 * 列表总代理
 */
export async function limitGeneralAgent(obj){
    return await postToShareServer('/limit-general-agent',obj);
}